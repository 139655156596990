import { IntegrationContext } from './IntegrationContext';
import { IMessageQueueMessage } from './MessageQueueMessage';

export type MQHandlerContext = Omit<IntegrationContext, 'user'>;

export abstract class MessageQueueFunction {
  abstract handleMessage(): Promise<void>;
}

export function withMessageQueue<T extends MessageQueueFunction>(
  queue: string,
  MessageQueueClass: {
    new (message: IMessageQueueMessage, context: MQHandlerContext): T;
  },
) {
  return {
    queue,
    handleMessage: (message: IMessageQueueMessage, context: MQHandlerContext) =>
      new MessageQueueClass(message, context).handleMessage(),
  };
}
